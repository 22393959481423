import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ScrollToTop from "../../Middleware/ScrollToTop";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Constants } from "../../Middleware/Constant";
import parse from "html-react-parser";
import { DataContext } from "../Context/SidebarContext";

export default function Footer() {
  const [footerData, setFooterData] = useState(null);
  const { data } = useContext(DataContext);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const ads = data?.data;
  const content =
    typeof ads?.billboard_ads === "string" ? ads?.billboard_ads : "";

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        setError("");
        const response = await fetch(Constants.backendUrl + "footer", {
          headers: {
            Authorization: `Basic ${Constants.token}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Something went wrong");
        }

        const jsonData = await response.json();
        if (jsonData.code === 401) throw new Error("Data Not Found");
        setFooterData(jsonData);
        setError("");
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <footer>
      <div className="container mx-auto lg:px-7 ">
        {content && (
          <div className="mb-5 flex justify-center">{parse(content)}</div>
        )}
        <div className="footer-bg sm:pt-16 sm:pb-20 pt-8 pb-8 lg:px-20 sm:px-16 ">
          <div className="flex md:flex-row flex-col md:justify-between md:items-end sm:mb-12 mb-2">
            <div className="self-center">
              <a
                href="https://asiantraderawards.co.uk/"
                target="_blank"
                aria-label={`Asian trader`}
              >
                <img
                  className="lg:w-full sm:h-24 h-16"
                  src={footerData?.data?.asian_awards_logo}
                  alt=""
                />
              </a>
            </div>
            <div className="lg:text-3xl md:text-2xl sm:text-lg text-sm  md:pt-0 pt-5 md:pl-0 sm:pl-5 pl-8 text-white font-light text-start md:text-right">
              {footerData?.data?.asian_awards_title}
            </div>
          </div>

          <div className="sm:ml-0 ml-5">
            <div className="swiper mySwiper award-section">
              {footerData?.data?.asian_awards_lists && (
                <Swiper
                  key={"footer"}
                  slidesPerView={4}
                  spaceBetween={20}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  breakpoints={{
                    320: { slidesPerView: 1.5 },
                    460: { slidesPerView: 2 },
                    768: { slidesPerView: 3 },
                    1120: { slidesPerView: 4 },
                  }}
                  className="mySwiper award-section"
                >
                  {footerData?.data?.asian_awards_lists?.map((award, index) => (
                    <SwiperSlide key={index}>
                      <div>
                        <div className="h-[180px]">
                          <img
                            src={award.asian_awards_image}
                            alt=""
                            className="w-full object-cover h-full"
                          />
                        </div>
                        <div className="bg-[#000A29] text-[#DFDFDF] text-xs px-6 text-center min-h-[65px] flex items-center justify-center">
                          <span className="font-light sm:text-[10px] text-[10px] leading-5">
                            {award?.asian_awards_title}
                          </span>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              )}
            </div>
          </div>
        </div>

        <div className="bg-[#141313] sm:px-20 px-8">
          <div className="flex justify-between flex-col md:flex-row pt-5 md:pt-0 gap-10 items-center">
            <div className="md:w-1/4  order-1 md:order-1">
              <div className="">
                <img src={footerData?.data?.footer_logo} alt="Logo_at" />
              </div>
            </div>
            <div className="md:w-1/4 order-3 md:order-2">
              <img
                src={footerData?.data?.footer_logo_2}
                className="mx-auto md:mx-0  max-w-200px md:max-w-full"
                alt="Logo_at2"
              />
            </div>
            <div className="md:w-1/2 order-2 md:order-3">
              <div className="text-white lg:text-sm text-xs sm:pl-5">
                <p className="wow fadeInDown text-white text-justify text-14 lg:leading-6 leading-4">
                  {footerData?.data?.footer_content}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-[#EA1717] md:px-20 px-10">
          <div className="flex justify-between flex-col md:flex-row md:gap-10 w-full  items-center">
            <div className=" sm:text-[.7rem] text-[10px] text-nowrap text-center gap-4 pt-3 md:pt-0 border-b md:pb-0 pb-2 md:border-none  text-white flex md:gap-5">
              {footerData?.data?.bottom_menu?.map((item, index) => (
                <React.Fragment key={index}>
                  {item.menu_link ? (
                    <a href="#" onClick={ScrollToTop}>
                      {item.menu_title}
                    </a>
                  ) : (
                    <Link to={`/${item.menu_slug}`} onClick={ScrollToTop}>
                      {item.menu_title}
                    </Link>
                  )}
                </React.Fragment>
              ))}
            </div>

            <div className="sm:w-full w-[310px] flex md:justify-end sm:justify-center justify-between ">
              <div className="text-white block sm:hidden text-sm text-left uppercase pb-[2px]">
                <Link
                  to={"/"}
                  className="uppercase text-[10px]"
                  onClick={() => ScrollToTop()}
                >
                  Asian traders
                </Link>
              </div>
              <div className="text-white  sm:text-xs text-[10px] text-right uppercase sm:py-3 py-1">
                <p className="uppercase text-nowrap">
                  {footerData?.data?.copyright}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
